import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Home/',
  },
  {
    path: '/Home/',
    name: '首页',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/Description/',
    name: '展馆简介',
    component: () => import('../views/Description.vue')
  },
  {
    path: '/Direactions/',
    name: '交通指南',
    component: () => import('../views/Direactions.vue')
  },
  {
    path: '/Notice/',
    name: '参观须知',
    component: () => import('../views/Notice.vue')
  },
  {
    path: '/Rules/',
    name: '开放制度',
    component: () => import('../views/Rules.vue')
  },
  {
    path: '/Reservation/',
    name: '预约',
    children: [
      {
        path: '/',
        redirect: 'New/',
      },
      {
        path: 'Group',
        name: '团体预约',
        component: () => import('../views/Reservation/Group.vue')
      },
      {
        path: 'Personal',
        name: '个人预约',
        component: () => import('../views/Reservation/Personal.vue')
      },
      {
        path: 'Search',
        name: '预约记录',
        component: () => import('../views/Reservation/Search.vue')
      },
    ],
    component: () => import('../views/Reservation.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
