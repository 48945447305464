<template>
    <div>
        <wd-navbar light>
            <div slot="left" @click="$router.back(-1)">
                <wd-icon name="arrow-left" />
            </div>
            <div class="title">
                <span>{{ $route.name }}</span>
            </div>
            <span slot="right">
                <!-- <router-link to="/My/Bag/"><wd-icon class="right" name="bags" size="20px"/></router-link> -->
            </span>
        </wd-navbar>
        <router-view style="padding-bottom: 64px;"/>
        <!-- <wd-tabbar fixed border v-model="tabbar">
            <wd-tabbar-item icon="wd-icon-spool" to="/Home/">首页</wd-tabbar-item>
            <wd-tabbar-item icon="wd-icon-goods" to="/Shop/">商店</wd-tabbar-item>
            <wd-tabbar-item icon="wd-icon-chat" to="/My/">我的</wd-tabbar-item>
        </wd-tabbar> -->
    </div>
</template>

<script>
// import axios from "axios";
// import Common from "@/components/Common";
// var apiurl = Common.APIurl;

export default {
    data() {
        return {
            tabbar: null,
            show: false,
        };
    },
    // created() {
    //     axios
    //         .get(apiurl + "UserCenter/ProfileCurrent")
    //         .then((response) => {
    //             var code = response.data.code;
    //             if (code == -1 && (this.$route.path.indexOf("Login") == -1)) {
    //                 this.$toast("建议登录后体验更多内容 ✧(≖ ◡ ≖)");
    //                 this.$router.push({ path: "/User/Login/" });
    //             }
    //         })
    //         .catch((error) => {
    //             this.$toast.error(error.message);
    //         });
    // },
};
</script>

<style>
a {
	text-decoration: none;
	outline: none;
	color: #000;
}

.wd-cell {
    background: transparent;
}
.wd-cell-group__body {
    background: transparent;
}
.wd-cell-group__title {
    background: transparent;
}

.mys-header {
    padding: 32px;
    background: #fff;
    min-height: 20vh;
}

.mys-header-title {
    text-align: left;
    font-size: 36px;
    font-weight: 400;
    color: #fff;
}

.mys-content {
    padding: 20px;
}

.mys-shop-item-icon-large {
    width: 100%;
    border-radius: 4px;
}
.mys-shop-item-icon-small {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-right: 12px;    
}
</style>