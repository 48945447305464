import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'
import router from './router'
import WotDesign from 'wot-design'
import 'wot-design/lib/theme-default/index.css'

Vue.use(WotDesign)

Axios.defaults.withCredentials = true;
Axios.defaults.timeout = 10000;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
